<template>
  <div class="create-reward">
    <CRow class="mb-3">
      <CCol col="12">
        <LocalBreadcrumb
          v-if="!isNoTitle"
          :pageNumber="pageNumber"
          :items="[rewardsTexts.pageTitle, rewardsTexts.pageCreationTitle]"
          :toolTipText="descriptions.rewardCreation"
          @setCurrentPage="setCurrentPage"
        />
      </CCol>
      <CCol col="12" class="d-flex justify-content-between align-items-center flex-wrap-reverse">
        <ClPageToolbar @toggleEditorToolbar="handleToggleEditorToolbar"  />
        <div>
          <fieldset name="saveReward" class="saveReward">{{ rewardsTexts.createPage.createRewardError }}</fieldset>
        </div>
        <div class="d-flex justify-content-end align-items-center pb-2">
          <CButton
            :color="btnColor('secondary')"
            class="mr-2 zq--custom-button"
            @click="cancelReward"
          >
            {{ $t('buttons.cancel') }}
          </CButton>
          <CButton
            :color="btnColor('success')"
            class="zq--responsive-button__common"
            @click="createReward"
            v-if="isCreate"
          >
            {{ $t('buttons.create') }}
          </CButton>
          <CButton
            v-else
            :color="btnColor('success')"
            class="zq--responsive-button__common"
            @click="createReward"
          >
            {{ $t('buttons.update') }}
          </CButton>
        </div>
      </CCol>
    </CRow>
    <!-- Form   -->
    <div class="create-form">
      <div class="form-content">
        <!--   Icon   -->
        <CIIconField
          v-model="formData.icon"
        />
        <!--   Reward Name   -->
        <ClFormRow
          :label="rewardsTexts.createPage.nameLabel"
          :toolTipText="descriptions.name"
        >
          <template #content>
            <CInput
              :placeholder="rewardsTexts.createPage.namePlaceholder"
              v-model.trim="formData.name"
              add-input-classes="col-sm-12"
              required
              :invalidFeedback="rewardsTexts.invalidFeedback"
              :is-valid="rewardNameValidate"
            />
          </template>
        </ClFormRow>
        <!--    Tags    -->
        <CITagField
          type="Reward"
          v-model="formData.tags"
          label="Reward tags"
          :tooltip="descriptions.rewardTags"
        />
        <!--  Value -->
        <ClFormRow
          :label="rewardsTexts.createPage.valueLabel"
          :toolTipText="descriptions.value"
        >
          <template #content>
            <CInput
              type="number"
              :placeholder="rewardsTexts.createPage.valuePlaceholder"
              v-model.number="formData.rewardValue"
              @update:value="handleUpdateNumberValue($event,'rewardValue', '0.1')"
              min="0.1"
              step="0.1"
              required
              :invalidFeedback="texts.invalidFeedback"
              :is-valid="rewardValueValidate"
            />
          </template>
        </ClFormRow>
        <!--    Reward Type    -->
        <ClFormRow
          :label="rewardsTexts.createPage.typeLabel"
          :toolTipText="descriptions.rewardType"
        >
          <template #content>
            <multiselect
              v-model="formData.rewardType"
              :options="rewardTypesOptions"
              :multiple="false"
              @search-change="searchRewardType"
              label="name"
              track-by="id"
              :selectLabel="selectLabel"
              placeholder="Type here..."
              :hide-selected="false"
              class="custom-multi-select zq--multiselect zq--multiselect__single"
              :class="{'zq--select--error': rewardTypeValidate === false}"
            >
              <template #caret>
                <div></div>
              </template>
              <span slot="noResult">No matches found</span>
            </multiselect>
            <img
              src="../../../../../assets/icons/search.svg"
              width="16"
              height="16"
              alt="search"
              class="zq--multiselect-search-icon"
            >
            <div v-if="rewardTypeValidate === false" class="icon-validation-error">{{ texts.invalidFeedback }}</div>
          </template>
        </ClFormRow>
        <!--   Reward Rank   -->
        <ClFormRow
          :label="rewardsTexts.createPage.rankLabel"
          :toolTipText="descriptions.rewardRank"
          v-if="isRewardRankField"
        >
          <template #content>
            <CInput
              :placeholder="rewardsTexts.createPage.rankPlaceholder"
              v-model.trim="formData.rewardRank"
              add-input-classes="col-sm-12"
              required
              :invalidFeedback="rewardsTexts.invalidFeedback"
              :is-valid="rewardRankValidate"
            />
          </template>
        </ClFormRow>

        <!--    Description    -->
        <ClFormRow
          :label="rewardsTexts.createPage.descriptionLabel"
          :toolTipText="descriptions.description"
        >
          <template #content>
            <ClEditor
              @change="onEditorChange"
              :is-toolbar="isToolbar"
              :value-prop="formData.description"
              :placeholder="rewardsTexts.createPage.descriptionPlaceholder"
            />
          </template>
        </ClFormRow>
        <!-- Acknowledgement required   -->
        <ClFormRow
          :label="rewardsTexts.createPage.acknowledgementLabel"
          tooltip-position=""
          col-label="4"
          md-content="3"
          col-content="3"
        >
          <template #content>
            <div v-touch:swipe="swipeAcknowledgment" class="zq--row-switch__tooltip">
              <ClSwitch
                :initState="isAcknowledgmentRequired"
                @update="acknowledgmentUpdate"
              />
              <IconWithTooltip :text="descriptions.acknowledgementRequired"
              />
            </div>
          </template>
        </ClFormRow>

        <ClFormRow
          :label="'Custom Award Scheduling'"
          tooltip-position=""
          col-label="4"
          md-content="3"
          col-content="3"
        >
          <template #content>
            <div v-touch:swipe="swipeCustomAwardScheduling" class="zq--row-switch__tooltip">
              <ClSwitch
                :initState="isCustomAwardScheduling"
                @update="customAwardSchedulingUpdate"
              />
              <IconWithTooltip :text="descriptions.acknowledgementRequired"
              />
            </div>
          </template>
        </ClFormRow>

        <ZqAwardScheduling
          v-if="isCustomAwardScheduling"
          :value="formData.scheduling"
          :label="'Scheduling'"
          :tooltip="'Scheduling tooltip'"
          :disabled="false"
          :hidden="false"
          :placeholder="'placeholder'"
          @input="updateScheduling"
          :key="schedulingKey"
        />

        <!--    Issue Limit    -->
        <ClFormRow
          :label="rewardsTexts.createPage.limitLabel"
          :toolTipText="descriptions.issueLimit"
        >
          <template #content>
            <CInput
              type="number"
              :placeholder="rewardsTexts.createPage.limitPlaceholder"
              v-model.number="formData.issueLimit"
              @update:value="handleUpdateNumberValue($event,'issueLimit')"
              min="1"
              step="1"
            />
          </template>
        </ClFormRow>
        <!--  CustomFields    -->
        <CICustomFields
          :type="model"
          @input="setCustomFieldData"
        />
        <!--    Metadata    -->
        <ClFormRow
          :label="rewardsTexts.createPage.metadataLabel"
          tooltipPosition="label"
          md-content="12"
          xl-content="12"
          :toolTipText="descriptions.metadata"
        >
          <template #content>
            <CMetaInputs
              v-on:change="addMetaData"
              :fields="formData.metadata"
            />
          </template>
        </ClFormRow>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { uniqueId, omit, cloneDeep } from 'lodash';
import { getLanguagesData, translationsTransform } from '@/utils/translationsUtils';
import { pageConfig, translationsConfig, formConfig } from '@/config';
import CMetaInputs from '@/components/forms/CMetaInputs';
import LocalBreadcrumb from '@/shared/components/LocalBreadcrumb';
import DatePicker from '@/shared/components/DatePicker';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import ClSwitch from '@/shared/components/formComponents/ClSwitch';
import ClFormRow from '@/shared/components/formComponents/ClFormRow';
import ClPageToolbar from '@/shared/components/ClPageToolbar';
import ClEditor from '@/shared/components/formComponents/ClEditor';
import { achievements } from '@/config/descriptions/achievements';
import { achievementsTexts } from '@/config/pageTexts/achievements.json';
import { rewardsTexts } from '@/config/pageTexts/rewards.json';
import { awardsTexts } from '@/config/pageTexts/awards.json';
import SwitchRow from '@/shared/components/SwitchRow';
import ClSelect from "@/shared/components/formComponents/ClSelect.vue";
import ZqAwardScheduling from "@/shared/components/ZqAwardScheduling.vue";

export default {
  name: 'CreateReward',
  components: {
    ZqAwardScheduling,
    ClSelect,
    CMetaInputs,
    IconWithTooltip,
    LocalBreadcrumb,
    DatePicker,
    ClSwitch,
    ClFormRow,
    ClPageToolbar,
    ClEditor,
    SwitchRow
  },
  props: {
    fileData: Object,
    isRewardRankField: {
      type: Boolean,
      default: true
    },
    pageNumber: {
      type: [String, Number],
      default: '5'
    },
    rewardData: {
      type: Object,
      default() {
        return {};
      }
    },
    isNoTitle: {
      type: Boolean,
      default: false
    },
    isCreate: {
      type: Boolean,
      default: true
    },
    entityModel: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      texts: {
        ...achievementsTexts
      },
      rewardsTexts: {
        ...rewardsTexts
      },
      awardsTexts: {
        ...awardsTexts
      },
      endDateErrorMessage: achievements.create.scheduling.endDateErrorMessage,
      model: 'Reward',
      selectLabel: formConfig.selectLabel,
      tagPlaceholder: formConfig.tagPlaceholder,
      isAcknowledgmentRequired: true,
      isCustomAwardScheduling: false,
      rewardType: null,
      currentLang: translationsConfig.languages[0],
      rewardTypesOptions: [],
      itemsPerPage: pageConfig.itemsPerPage,
      query: {},
      sortBy: [
        {
          queryField: 'name',
          order: 'Asc',
        },
      ],
      page: pageConfig.page,
      pointDate: '',
      languages: translationsConfig.languages,
      langData: {},
      onStatusChangeToOptions: ['Issued', 'Claimed', 'Completed', 'Held', 'Processing', 'Delivered', 'Expired', 'Cancelled', 'Declined'],
      startDate: '',
      endDate: null,
      schedulingKey: 1,
      formData: {
        customFields: {},
        issueLimit: null,
        rewardRank: 1,
        name: '',
        rewardValue: '',
        rewardType: '',
        rewardTypeId: '',
        description: '',
        delay: '',
        icon: '',
        period: '',
        pointInTime: null,
        tags: [],
        metadata: {},
        translations: [],
        addConstraints: [],
        scheduling: []
      },
      intFields: ['delay', 'period'],
      tagsOptions: [],
      rewardNameValidate: null,
      rewardValueValidate: null,
      rewardTypeValidate: null,
      rewardDelayValidate: true,
      pointInTimeStartDateValidate: null,
      invalidPointInTimeStartDateMessage: null,
      rewardRankValidate: null,
      isToolbar: false,
      descriptions: {
        rewardCreation: achievements.list.create.rewards.rewardCreation.rewardCreation,
        icon: achievements.list.create.rewards.rewardCreation.icon,
        name: achievements.list.create.rewards.rewardCreation.name,
        rewardRank: achievements.list.create.rewards.rewardCreation.rewardRank,
        description: achievements.list.create.rewards.rewardCreation.description,
        acknowledgementRequired: achievements.list.create.rewards.rewardCreation.acknowledgementRequired,
        issueLimit: achievements.list.create.rewards.rewardCreation.issueLimit,
        value: achievements.list.create.rewards.rewardCreation.value,
        rewardType: achievements.list.create.rewards.rewardCreation.rewardType,
        delay: achievements.list.create.rewards.rewardCreation.delay,
        period: achievements.list.create.rewards.rewardCreation.period,
        pointInTime: achievements.list.create.rewards.rewardCreation.pointInTime,
        rewardTags: achievements.list.create.rewards.rewardCreation.rewardTags,
        metadata: achievements.list.create.rewards.rewardCreation.metadata,
        languages: achievements.list.create.rewards.rewardCreation.languages,
      },
      invalidEndDate: false
    };
  },
  created() {
    this.initialize();
  },
  mounted() {
    if (this.$refs.pointInTimeRef && this.$refs.pointInTimeRef.$el) {
      if (!this.formData.pointInTime) {
        this.$refs.pointInTimeRef.$el.classList.add('displayNone');
      } else {
        this.$refs.pointInTimeRef.$el.classList.add('displayBlock');
        this.isPointInTime = true;
      }
    }
  },
  computed: {
    ...mapGetters('rewardTypes', ['rewardTypes']),
    ...mapGetters('theme', ['theme']),
    rewardsDescriptions() {
      const context = require.context('@/config/descriptions/', true, /\.json$/);
      const descriptions = {};

      context.keys().forEach((key) => {
        const modelName = key.replace(/\.\/|\.json/g, '');
        descriptions[modelName] = context(key);
      });

      return descriptions;
    }
  },
  methods: {
    ...mapActions('rewardTypes', ['handleGetRewardTypes', 'handleGetRewardTypesByQuery']),
    getDescription() {
      if (this.rewardsDescriptions[this.entityModel]) {
        const mainModel = this.rewardsDescriptions[this.entityModel]
        this.descriptions = mainModel[this.entityModel]['create']['rewardCreation']
      }
    },
    async initialize() {
      this.getDescription()
      this.rewardTypesOptions = await this.handleGetRewardTypesByQuery({
        queryRequest: {
          sortBy: this.sortBy,
          limit: 20,
          skip: 0
        }
      });

      this.langData = getLanguagesData(this.languages);

      if (Object.keys(this.rewardData).length) {
        this.getTemplateData(this.rewardData);
        if (this.rewardData.scheduling && this.rewardData.scheduling.length) {
          this.isCustomAwardScheduling = true;
        }
      }

      this.formData.icon = this.fileData.icon ? this.fileData.icon : '';
    },
    getTemplateData(rewardData) {
      this.formData.rewardRank = rewardData.rewardRank;
      this.formData.rewardValue = rewardData.rewardValue;
      this.formData.rewardType = cloneDeep(rewardData.rewardType);
      this.formData.rewardTypeId = rewardData.rewardType.id;

      if (rewardData.addConstraints) {
        this.formData.addConstraints = cloneDeep(rewardData.addConstraints);
      } else if (rewardData.constraints) {
        this.formData.addConstraints = cloneDeep(rewardData.constraints);
      }

      this.formData.customFields = cloneDeep(rewardData.customFields);
      this.formData.tags = cloneDeep(rewardData.tags);
      this.formData.metadata = rewardData.metadata ? cloneDeep(rewardData.metadata) : {};
      this.formData.name = rewardData.name;
      this.formData.description = rewardData.description ?? '';
      this.formData.icon = rewardData.icon ?? '';
      this.formData.issueLimit = rewardData.issueLimit;
      this.formData.delay = rewardData.delay;
      this.formData.period = rewardData.period;
      this.formData.scheduling = rewardData.scheduling ? cloneDeep(rewardData.scheduling) : [];
      this.formData.id = rewardData.id;

      if (!this.formData.addConstraints) this.formData.addConstraints = [];

      this.isAcknowledgmentRequired = this.formData.addConstraints.includes('memberAcknowledgmentRequired');

      this.schedulingKey++;
    },
    btnColor(color) {
      switch (this.theme) {
        case 'main': {
          return '';
        }
        case 'default': {
          return color;
        }
        default : {
          return '';
        }
      }
    },
    handleUpdateNumberValue(val, field, minValue = 0) {
      if (field === 'delay') {
        const delayElement = document.getElementsByName('delay')[0];
        delayElement.parentElement.classList.remove('zq-invalid');

        const pointInTimeElement = document.getElementsByName('pointInTime')[0];
        pointInTimeElement.closest('.zq--data-picker--wrap').classList.remove('zq-invalid');
        pointInTimeElement.closest('.zq--data-picker--wrap').children[0].classList.remove('zq-invalid');
      }

      if (this.intFields.includes(field)) {
        if (typeof val === 'string') {
          val = val.replace(/[^0-9.]/g, '');
          if (this.txt) {
            this.formData[field] = parseInt(val);
          }
        } else {
          val = Math.trunc(val);
          this.formData[field] = val;
        }
        const element = document.getElementsByName(field)[0];
        element.value = this.formData[field];
      }

      if (val && val < Number(minValue)) {
        this.formData[field] = minValue;
      } else {
        this.formData[field] = val;
      }
    },
    async searchRewardType(val) {
      if (val.length >= 1) {
        this.rewardTypesOptions = await this.handleGetRewardTypesByQuery({
          queryRequest: {
            multiFields: [
              {
                queryFields: ['name'],
                queryValue: val
              }
            ],
            sortBy: this.sortBy,
          }
        });
      }
      if (val.length === 0) {
        this.rewardTypesOptions = await this.handleGetRewardTypesByQuery({
          queryRequest: {
            sortBy: this.sortBy,
            limit: 20,
            skip: 0
          }
        });
      }
    },
    disabledStartDate(d) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return d < new Date(new Date().setHours(0, 0, 0, 0));
    },
    setCustomFieldData(val) {
      this.formData.customFields = { ...val };
    },
    updateScheduling(data) {
      const scheduling = data.filter(data => data.onStatusChangeTo);
      this.formData.scheduling = scheduling;
    },
    createReward() {
      this.rewardNameValidate = !!this.formData.name;
      this.rewardValueValidate = !!this.formData.rewardValue;
      this.rewardTypeValidate = !!this.formData.rewardType;
      this.rewardDelayValidate = !!this.formData.delay;

      this.formData.translations = translationsTransform(this.langData);

      // if ((this.formData.pointInTime && this.formData.delay) || (!this.formData.pointInTime && !this.formData.delay)) {
      //   this.rewardDelayValidate = false;
      //
      //   const delayElement = document.getElementsByName('delay')[0];
      //   delayElement.parentElement.classList.add('zq-invalid');
      //
      //   const pointInTimeElement = document.getElementsByName('pointInTime')[0];
      //   pointInTimeElement.closest('.zq--data-picker--wrap').classList.add('zq-invalid');
      //   pointInTimeElement.closest('.zq--data-picker--wrap').children[0].classList.add('zq-invalid');
      //
      // } else {
      //   this.rewardDelayValidate = true;
      //
      //   const delayElement = document.getElementsByName('delay')[0];
      //   delayElement.parentElement.classList.remove('zq-invalid');
      //
      //   const pointInTimeElement = document.getElementsByName('pointInTime')[0];
      //   pointInTimeElement.closest('.zq--data-picker--wrap').classList.remove('zq-invalid');
      //   pointInTimeElement.closest('.zq--data-picker--wrap').children[0].classList.remove('zq-invalid');
      // }

      if (this.formData.rewardType) this.formData.rewardTypeId = this.formData.rewardType.id;

      if (this.isAcknowledgmentRequired) {
        if (!Array.isArray(this.formData.addConstraints)) {
          this.formData.addConstraints = [];
        }
        this.formData.addConstraints.push('memberAcknowledgmentRequired');
      } else {
        const idx = this.formData.addConstraints.findIndex(e => e === 'memberAcknowledgmentRequired');
        if (idx !== -1) {
          this.formData.addConstraints.splice(idx, 1);
        }
      }

      if (
          this.rewardNameValidate &&
          this.rewardValueValidate &&
          this.rewardTypeValidate
      ) {
        if (!this.formData.id) {
          this.formData.id = uniqueId('nxt_');
        }

        this.formData.pointInTime = !this.formData.pointInTime ? null : this.formData.pointInTime;
        this.formData.delay = !this.formData.delay ? 0 : this.formData.delay;
        this.formData.period = !this.formData.period ? 0 : this.formData.period;

        this.$emit('createReward', this.formData, 'Competition');
      }
    },
    onEditorChange(html) {
      this.formData.description = html;
    },
    cancelReward() {
      this.$emit('cancelReward');
    },
    addTagsGroups(newGroups) {
      this.tagsOptions.push(newGroups);
      this.formData.tags.push(newGroups);
    },
    addMetaData(meta) {
      this.formData.metadata = meta;
    },
    setCurrentPage(val) {
      this.$emit('setPage', { page: val });
    },
    selectAnIcon() {
      this.$emit('setPage', { page: 'repositories' });
    },
    handlePreviewIcon() {
      this.$emit('setPage', { page: 'previewFile', data: { id: this.formData.icon, from: 'Reward creation' } });
    },
    swipeAcknowledgment(direction) {
      this.isAcknowledgmentRequired = direction === 'right';
    },
    acknowledgmentUpdate(val) {
      this.isAcknowledgmentRequired = val;
    },
    swipeCustomAwardScheduling(direction) {
      this.isCustomAwardScheduling = direction === 'right';
    },
    customAwardSchedulingUpdate(val) {
      this.isCustomAwardScheduling = val;
    },
    setCurrentTranslation(lang) {
      this.currentLang = lang;
    },
    handleNameTranslate(name) {
      if (!name) {
        this.langData[this.currentLang] = omit(this.langData[this.currentLang], 'name');
      } else {
        this.langData[this.currentLang] = {
          ...this.langData[this.currentLang],
          name: name.trim()
        };
      }
    },
    handleDescriptionTranslate(description) {
      if (!description) {
        this.langData[this.currentLang] = omit(this.langData[this.currentLang], 'description');
      } else {
        this.langData[this.currentLang] = {
          ...this.langData[this.currentLang],
          description: description.trim()
        };
      }
    },
    handleToggleEditorToolbar(val) {
      this.isToolbar = val;
    },
  },
  watch: {
    formData: {
      deep: true,
      handler: function (val) {
        if (val.name) this.rewardNameValidate = true;
        if (val.rewardValue) this.rewardValueValidate = true;
        if (val.rewardType) this.rewardTypeValidate = true;
        if (val.delay) this.rewardDelayValidate = true;

        // Validate if rewardRank
        if (this.isRewardRankField) {
          if (val.rewardRank) this.rewardRankValidate = true;
        }

      },
    },
    rewardType(val) {
      if (val) {
        this.formData.rewardType = val.name;
        this.formData.rewardTypeId = val.id;
      }
    }
  },
}
</script>

<style lang="scss">
.create-reward {
  height: 100%;

  .form-content {
    background: var(--zq-sub-bg);
    height: 100%;

    .icon-subtitle__preview {
      cursor: pointer;
      color: #321fdb;
      padding-left: 2em;

      &:hover {
        text-decoration: underline;
      }
    }

    .create-reward-date-picker {
      .zq--data-picker.mx-datepicker {
        width: 100%;
      }
    }
  }

  .displayNone {
    display: none;
  }

  .saveReward {
    display: none;
  }

  .zq-invalid {
    .saveReward {
      display: unset;
      color: var(--zq-warn);
    }
  }
}
</style>
