<template>
  <div class="create-tiles-step">
    <CForm
      ref="createSettings"
      novalidate
      class="create-form"
    >
      <div class="form-content content col-12">
        <CRow class="p-0 m-0">
          <CCol col="6" class="d-flex p-0">
            <h3 class="mb-3 mr-1 bread-content">{{ stepNumber + '. ' + pageTitle }}</h3>
            <IconWithTooltip class="zq--header-tooltip"/>
          </CCol>
          <CCol col="6" class="d-flex p-0 justify-content-end" v-if="instantWinType === 1">
            <CButton
              @click="handleWheelPreview"
              :disabled="isWheelPreviewDisabled"
              class="wizard-action-btn zq--responsive-button__common"
            >
              {{ isWheelPreview ? $t('buttons.goBack') : $t('buttons.wheelPreview') }}
            </CButton>
          </CCol>
        </CRow>

        <CRow class="p-0 m-0 col-12">
          <div v-if="!isWheelPreview" class="col-12">
            <CRow class="p-0 m-0 col-12">
              <CCol col="6" class="p-0 col-6">
                <div v-if="instantWinType === 1" class="wheel-settings w-100 flex-column">
                  <div class="mb-4 settings-title">{{ texts.createPage.tilesStep.wheelSettingsTitle }}</div>

                  <!--                  AVAILABLE IF CLIENT WANTS           -->

                  <div v-if="isWheelSettingsAvailable">
                    <div class="mb-0 settings-title">{{ texts.createPage.tilesStep.spinButtonSettingsTitle }}</div>
                    <!--  Button  Text   -->
                    <div class="flex-column text-block">
                      <span class="tag-label-text">{{ texts.createPage.tilesStep.buttonTextLabel }}</span>
                      <ClEditor
                        ref="spinButtonText"
                        @change="onEditorSpinButtonTextChange"
                        :is-toolbar="true"
                        :value-prop="localWheelSettings.buttonText"
                        :placeholder="'placeholder'"
                        :disabled="false"
                        :name="'spinButtonText'"
                        :editorOptionsProps="editorOptions"
                        :editorHeight="50"
                      />
                    </div>
                    <div class="d-flex align-items-center mb-3">
                      <!--   Icon   -->
                      <div class="flex-column w-50 mr-5">
                        <CIIconField
                          class="w-100"
                          v-model="localWheelSettings.icon"
                          :key="selectedTile.value"
                          @deleteFile="handleDeleteButtonIconFile"
                          :isColumn="true"
                          :isPopup="true"
                        />
                      </div>
                      <!--  Background Image   -->
                      <div class="flex-column w-50 mr-5">
                        <CIIconField
                          class="w-100"
                          v-model="localWheelSettings.spinButtonBackgroundImage"
                          :key="selectedTile.value"
                          :label="texts.createPage.tilesStep.spinBtnBgBackgroundImageLabel"
                          @deleteFile="handleDeleteButtonBackgroundFile"
                          :isColumn="true"
                          :isPopup="true"
                        />
                      </div>
                    </div>

                    <div class="d-flex align-items-center mb-4">
                      <!-- Spin Button background color -->
                      <div class="flex-column w-50">
                        <div class="zq--form-row--label pt-2">
                          <span class="tag-label-text">{{ texts.createPage.tilesStep.spinBtnBorderColorLabel }}</span>
                        </div>
                        <div class="d-flex align-items-center">
                          <ColorPicker
                            :key="localWheelSettings.spinButtonBorderColor"
                            @colorSelected="handleSpinButtonBorderColorSelected"
                            :selectedPropColor="localWheelSettings.spinButtonBorderColor"
                          />
                          <IconWithTooltip class="ml-2" :text="descriptions.wheelButtonBorderColor"/>
                        </div>
                      </div>
                      <!-- Spin Button background color -->
                      <div class="flex-column w-50">
                        <div class="zq--form-row--label pt-2">
                          <span class="tag-label-text">{{ texts.createPage.tilesStep.spinBtnBgColorLabel }}</span>
                        </div>
                        <div class="d-flex align-items-center">
                          <ColorPicker
                            :key="localWheelSettings.spinButtonBackground"
                            @colorSelected="handleSpinButtonBackgroundColorSelected"
                            :selectedPropColor="localWheelSettings.spinButtonBackground"
                          />
                          <IconWithTooltip class="ml-2" :text="descriptions.wheelButtonBgColor"/>
                        </div>
                      </div>
                    </div>
                    <div class="dividing-line mt-4 mb-4"></div>
                    <div class="mt-2 settings-title">{{ texts.createPage.tilesStep.wheelBorderSettingsTitle }}</div>

                    <div class="d-flex align-items-center mb-4">
                      <!-- Wheel border background color -->
                      <div class="flex-column w-50 mr-5">
                        <div class="zq--form-row--label pt-2">
                          <span class="tag-label-text">{{ texts.createPage.tilesStep.wheelBgColorLabel }}</span>
                        </div>
                        <div class="d-flex align-items-center">
                          <ColorPicker
                            :key="localWheelSettings.wheelBackground"
                            @colorSelected="handleWheelBackgroundColorSelected"
                            :selectedPropColor="localWheelSettings.wheelBackground"
                          />
                          <IconWithTooltip class="ml-2" :text="descriptions.wheelBgColor"/>
                        </div>
                      </div>
                      <!-- Wheel borders  color -->
                      <div class="flex-column w-50 mr-5">
                        <div class="zq--form-row--label pt-2">
                          <span class="tag-label-text">{{ texts.createPage.tilesStep.wheelBordersColorLabel }}</span>
                        </div>
                        <div class="d-flex align-items-center">
                          <ColorPicker
                            :key="localWheelSettings.wheelBordersColor"
                            @colorSelected="handleWheelBordersColorSelected"
                            :selectedPropColor="localWheelSettings.wheelBordersColor"
                          />
                          <IconWithTooltip class="ml-2" :text="descriptions.wheelBorderColor"/>
                        </div>
                      </div>
                    </div>
                    <div class="dividing-line mt-4 mb-4"></div>
                  </div>

                  <div class="settings-title">{{ texts.createPage.tilesStep.wholeWheelImageTitle }}</div>
                  <div class="d-flex align-items-center mb-3">
                    <!--   Wheel Image   -->
                    <div class="flex-column w-50 mr-5">
                      <CIIconField
                        class="w-100"
                        v-model="localWheelSettings.wheelBorderImage"
                        :label="texts.createPage.tilesStep.wheelBorderImageLabel"
                        @deleteFile="handleDeleteWheelBorderImageFile"
                        :isColumn="true"
                        :isPopup="true"
                      />
                    </div>
                    <!--  Wheel Button Image   -->
                    <div class="flex-column w-50  mr-5">
                      <CIIconField
                        class="w-100"
                        v-model="localWheelSettings.wheelButtonImage"
                        :label="texts.createPage.tilesStep.wheelButtonImageLabel"
                        @deleteFile="handleDeleteWheelButtonImageFile"
                        :isColumn="true"
                        :isPopup="true"
                      />
                    </div>
                  </div>

                  <div class="d-flex align-items-center mb-3">
                    <!--   Wheel Image   -->
                    <div class="flex-column w-50">
                      <CIIconField
                        ref="wheelImageIconField"
                        class="w-100"
                        v-model="localWheelSettings.wheelImage"
                        :label="texts.createPage.tilesStep.wheelImageLabel"
                        @deleteFile="handleDeleteWheelImageFile"
                        :isColumn="true"
                        :isPopup="true"
                      />
                    </div>
                    <div class="flex-column w-25">
                      <div class="zq--form-row--label">
                        <span class="tag-label-text">{{ texts.createPage.tilesStep.showWheelImageLabel }}</span>
                      </div>
                      <div class="d-flex align-items-center">
                        <CSwitch
                          color="success"
                          shape="pill"
                          :value="isShowWheelImage"
                          :checked="isShowWheelImage"
                          class="zq--switch"
                          :labelOn="'on'"
                          :labelOff="'off'"
                          :disabled="!localWheelSettings.wheelImage"
                          @update:checked="showWheelImageUpdate"
                        />
                        <IconWithTooltip class="ml-2" :text="descriptions.showWheelImage"/>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex align-items-center mb-3">
                    <!--   Wheel Arrow   -->
                    <div class="flex-column w-50 mr-5">
                      <CIIconField
                        ref="wheelArrowImageIconField"
                        class="w-100"
                        v-model="localWheelSettings.wheelArrowImage"
                        :label="texts.createPage.tilesStep.wheelArrowImageLabel"
                        @deleteFile="handleDeleteWheelArrowImageFile"
                        :isColumn="true"
                        :isPopup="true"
                      />
                    </div>
                  </div>
                </div>
              </CCol>

              <!-- Right block -->
              <CCol col="6" class="p-0 col-6 right-block">
                <div class="charts-switch-row" v-if="instantWinType === 1">
                  <TabSwitcher
                    class="tabSwitcher"
                    :tabs="tabsList"
                    :width="'422px'"
                    :height="'38px'"
                    v-model="activeTab"
                    @toggle="handleTabToggle"
                  />
                  <IconWithTooltip class="tabSwitcher-tooltip" :text="'Switch between Pie chart and Bar chart'"/>
                </div>
                <div class="create-wheel-wrapper" v-if="instantWinType === 1">
                  <!-- wheelPreview barGraph pieChart -->
                  <div :class="{ 'hidden': activeTab !== 'wheelPreview' }">
                    <Wheel
                      ref="wheel"
                      :sections-count="tilesCount"
                      :key="rerenderWheelKey"
                      :sectionsFilled="sectionsFilled"
                      :tilesData="localTiles"
                      :isVerticallyText="isVerticallyText"
                      @handleTileSelected="handleTileSelected"
                      :wheelSettings="localWheelSettings"
                      :isShowWheelImage="isShowWheelImage"
                    />
                  </div>

                  <WheelTilesPieChart
                    v-if="activeTab === 'pieChart'"
                    :data="tilesData"
                    :key="renderChartKey"
                    :chartsData="localChartsData"
                  />
                  <BarChart
                    v-if="activeTab === 'barGraph'"
                    :key="renderChartKey"
                    :tilesData="tilesData"
                    :chartsData="localChartsData"
                  />
                </div>
                <div class="card-preview-wrapper flex-column" v-if="instantWinType === 2">
                  <div class="d-flex align-items-center justify-content-between probability-view">
                    <div>
                      <div
                        @click="handleIsScratchCardProbabilityView()"
                        v-if="isScratchCardProbabilityView"
                        class="probability-view-btn"
                      >
                        <img src="@/assets/icons/blue-back-arrow.svg" alt="back-arrow"> Back
                      </div>
                    </div>
                    <div>
                      <div
                        @click="handleIsScratchCardProbabilityView()"
                        v-if="!isScratchCardProbabilityView"
                        class="probability-view-btn"
                      >
                        Probability view <img src="@/assets/icons/chartBar.svg" alt="chartBar">
                      </div>
                    </div>
                  </div>
                  <ScratchCard
                    v-if="!isScratchCardProbabilityView"
                    ref="scratchcard"
                    :tilesData="tilesData"
                    :tilesCount="tilesCount"
                    :cols="localCols"
                    :rows="localRows"
                    @handleTileSelected="handleTileSelected"
                    :selectedTile="selectedTile.value"
                  />
                  <BarChart
                    v-if="isScratchCardProbabilityView"
                    :key="renderChartKey"
                    :tilesData="tilesData"
                    :chartsData="localChartsData"
                    :instantWinType="instantWinType"
                  />
                </div>

              </CCol>
              <!-- Right block ended -->

              <CCol class="pt-2 pl-0">
                <CCol col="6" class="p-0 col-6">
                  <div class="dividing-line mt-4 mb-4"></div>
                  <span class="settings-title">{{ texts.createPage.tilesStep.tileSettingsTitle }}</span>

                  <div v-if="instantWinType === 2" class="d-flex align-items-center p-0 m-0 ">
                    <CIInputCol
                      type="number"
                      min="2"
                      max="7"
                      class="w-50 align-items-center pr-2"
                      :value="localCols"
                      :label="texts.createPage.tilesStep.colLabel"
                      v-model="localCols"
                      name="cols"
                      :tooltip="descriptions.numberOfCols"
                    />

                    <CIInputCol
                      type="number"
                      min="2"
                      max="7"
                      class="w-50 align-items-center"
                      :value="localRows"
                      :label="texts.createPage.tilesStep.rowLabel"
                      v-model="localRows"
                      name="rows"
                      :tooltip="descriptions.numberOfRows"
                    />
                  </div>
                  <div class="d-flex align-items-center justify-content-between">
                    <!--   How many tiles   -->
                    <CIInputCol
                      type="number"
                      min="2"
                      class="w-50 align-items-center pr-2"
                      :value="localTilesCount"
                      :label="texts.createPage.tilesStep.tilesCountLabel"
                      v-model="localTilesCount"
                      name="tilesCount"
                      :tooltip="descriptions.numberOfTiles"
                      :disabled="instantWinType === 2"
                    />
                    <!--   Choose tile   -->
                    <div class="flex-column w-50">
                      <span class="tag-label-text">{{ texts.createPage.tilesStep.tileLabel }}</span>
                      <div class="d-flex align-items-center">
                        <multiselect
                          class="custom-multi-select zq--multiselect w-100"
                          v-model="selectedTile"
                          label="label"
                          track-by="value"
                          :options="sectionsOptions"
                          :hide-selected="true"
                          @input="onInput"
                        >
                        </multiselect>
                        <IconWithTooltip :text="descriptions.selectTile" class="ml-2"/>
                      </div>
                    </div>
                  </div>
                  <!--   autoProbability   -->
                  <div class="flex-column w-25">
                    <div class="zq--form-row--label">
                      <span class="tag-label-text">{{ texts.createPage.tilesStep.autoProbabilityLabel }}</span>
                    </div>
                    <div class="d-flex align-items-center">
                      <CSwitch
                        color="success"
                        shape="pill"
                        :value="localAutoProbability"
                        :checked="localAutoProbability"
                        class="zq--switch"
                        :labelOn="'on'"
                        :labelOff="'off'"
                        :disabled="false"
                        @update:checked="autoProbabilityUpdate"
                      />
                      <IconWithTooltip class="ml-2" :text="descriptions.autoProbability"/>
                    </div>
                  </div>
                  <!--   Probability     -->
                  <CIInputCol
                    v-if="!localAutoProbability"
                    type="number"
                    step="0.000001"
                    min="0.000001"
                    max="0.999999"
                    :value="tile.probability"
                    :label="texts.createPage.tilesStep.probabilityLabel"
                    v-model="tile.probability"
                    name="probability"
                    :isTooltip="true"
                    :toolTipText="descriptions.probability"
                    :requiredText="requiredText"
                    @input="onProbabilityInput"
                  />

                  <!--  has prize - show reward-->
                  <div class="flex-column mt-3 mb-3">
                    <div class="zq--form-row--label">
                      <span class="tag-label-text">{{ texts.createPage.tilesStep.hasPrizeLabel }}</span>
                    </div>
                    <div class="d-flex align-items-center">
                      <CSwitch
                        color="success"
                        shape="pill"
                        :value="hasPrizes"
                        :checked="hasPrizes"
                        class="zq--switch"
                        :labelOn="'on'"
                        :labelOff="'off'"
                        :disabled="false"
                        @update:checked="hasPrizesUpdate"
                      />
                      <IconWithTooltip class="ml-2" :text="descriptions.hasPrize"/>
                    </div>
                  </div>
                </CCol>

                <div
                  class="flex-column col-6 p-0"
                  v-if="hasPrizes && !isWheelPreview && Object.keys(tile.reward).length !== 0"
                >
                  <div class="zq--form-row--label">
                    <span class="tag-label-text">{{ texts.createPage.tilesStep.rewardLabel }}</span>
                  </div>
                  <table v-if="Object.keys(tile.reward).length !== 0" class="reward-table">
                    <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Value</th>
                      <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr :key="tile.reward.id">
                      <td :title="tile.reward.name" class="ellipsis">{{ tile.reward.name }}</td>
                      <td :title="tile.reward.rewardType.key" class="ellipsis">{{ tile.reward.rewardType.key }}</td>
                      <td :title="tile.reward.rewardValue" class="ellipsis">{{ tile.reward.rewardValue }}</td>
                      <td>
                        <span class="link" @click="removeReward()">Delete</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <CCol col="6" class="p-0 col-6">
                  <!--   Text on the tile   -->
                  <div class="flex-column text-block">
                    <span class="tag-label-text">{{ texts.createPage.tilesStep.textLabel }}</span>
                    <ClEditor
                      ref="text"
                      @change="onEditorChange"
                      :is-toolbar="true"
                      :value-prop="tile.text"
                      :placeholder="'placeholder'"
                      :disabled="false"
                      :name="'text'"
                      :editorOptionsProps="editorOptions"
                      :editorHeight="100"
                    />
                  </div>
                  <div class="d-flex align-items-center justify-content-between">
                    <!--   Switch tile text position   -->
                    <div class="flex-column mt-3" v-if="instantWinType === 1">
                      <div class="zq--form-row--label">
                        <span class="tag-label-text">{{ texts.createPage.tilesStep.tileTextPositionLabel }}</span>
                      </div>
                      <div class="d-flex align-items-center">
                        <SwitchButton
                          :isChecked="isVerticallyText"
                          :textLabelOn="'Horizontally'"
                          :textLabelOff="'Vertically'"
                          name="verticallyTextToggle"
                          @toggle="swipeTextPositionHandler"
                          :height="'42px'"
                          :width="'200px'"
                        />
                        <IconWithTooltip :text="descriptions.tileTextPosition"/>
                      </div>
                    </div>

                    <!-- Tile background color and icon -->
                    <!--   Icon   -->
                    <CIIconField
                      v-model="tile.icon"
                      :key="selectedTile.value"
                      @deleteFile="handleDeleteFile"
                      :isColumn="true"
                      :isPopup="true"
                    />

                    <!-- Tile background color -->
                    <div class="flex-column" v-if="!isHiddenTileBgButton">
                      <div class="zq--form-row--label pt-2">
                        <span class="tag-label-text">{{ texts.createPage.tilesStep.tileBgColorLabel }}</span>
                      </div>
                      <div class="d-flex align-items-center">
                        <ColorPicker
                          :key="tile.background"
                          @colorSelected="handleColorSelected"
                          :selectedPropColor="tile.background"
                        />
                        <IconWithTooltip class="ml-2" :text="descriptions.tileBgColor"/>
                      </div>
                    </div>
                  </div>
                </CCol>
              </CCol>
            </CRow>
          </div>

          <!-- Wheel preview page -->
          <div
            v-else
            class="preview-wheel-wrapper"
          >
            <div :class="{ 'hidden': isShowCongratsWithConfetti }">
              <Wheel
                v-if="instantWinType === 1"
                ref="wheelPreview"
                :sections-count="tilesCount"
                :key="rerenderWheelKey"
                :sectionsFilled="sectionsFilled"
                :tilesData="localTiles"
                @handleTileSelected="handleTileSelected"
                @showReward="showReward"
                :isPreviewSpin="true"
                :isVerticallyText="isVerticallyText"
                :wheelSettings="localWheelSettings"
              />
            </div>
            <CongratulationWithConfetti
              v-if="isShowCongratsWithConfetti"
              ref="congratulationWithConfettiRef"
              @stopCongratsWithConfetti="stopCongratsWithConfetti"
              :winningTileReward="winningTileReward"
              :messageSettings="messageSettings"
            />
          </div>
          <!-- Wheel preview ended -->
        </CRow>
      </div>
    </CForm>
    <!-- MODALS   -->
    <Modal
      :modalShow="isShowModal"
      :messageGeneral="messageGeneral"
      :title="modalTitle"
      :cancelBtnLabel="'Add from template'"
      :successBtnLabel="'Create'"
      @doFunction="createReward"
      @closeModal="closeModal"
      v-on:toggle-modal="addRewardFromTemplate()"
      :isSuccessButton="true"
    />
    <RewardTemplatesModal
      :isShowRewardTemplatesModal="isShowRewardTemplatesModal"
      @selectReward="selectReward"
      @closeModal="closeModal"
    />
    <CreateRewardModal
      :isShowRewardModal="isShowRewardModal"
      :selectedReward="selectedReward"
      @updateRewardData="updateRewardData"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import { instantWinsTexts } from '@/config/pageTexts/instantWins.json';
import { instantWins } from '@/config/descriptions/instantWins.json';
import ClResponsiveButton from '@/shared/components/formComponents/ClResponsiveButton';
import CIInputCol from '@/shared/components/formComponents/CIInputCol.vue';
import CreateRewards from '@/shared/components/supportModels/rewards/AddRewards';
import { cloneDeep } from 'lodash';
import Wheel from '@/views/ziqni/instant-wins/components/Wheel';
import CIIconField from '@/shared/components/CIIconField/CIIconField';
import WheelTilesPieChart from '@/views/ziqni/instant-wins/components/WheelTilesPieChart';
import BarChart from '@/views/ziqni/instant-wins/components/BarChart';
import ScratchCard from '@/views/ziqni/instant-wins/components/ScratchCard.vue';
import SwitchButton from '@/shared/UI/SwitchButton';
import ClEditor from '@/shared/components/formComponents/ClEditor';
import { Quill } from 'vue2-editor';
import ColorPicker from '@/shared/UI/ColorPicker.vue';
import TabSwitcher from '@/shared/UI/TabSwitcher.vue';

import CongratulationWithConfetti from '@/shared/components/supportModels/instantWins/CongratulationWithConfetti.vue';
import { updateSelectedSection } from '@/views/ziqni/instant-wins/components/helpers/wheelSections';
import { mapActions } from 'vuex';
import Modal from '@/shared/UI/Modal.vue';
import RewardTemplatesModal from '@/views/ziqni/instant-wins/RewardTemplatesModal.vue';
import CreateRewardModal from '@/views/ziqni/instant-wins/CreateRewardModal.vue';

function getFontName(font) {
  return font.toLowerCase().replace(/\s/g, '-');
}

// Specify Quill fonts
const fontList = ['Arial', 'Courier', 'Garamond', 'Tahoma', 'Times New Roman', 'Verdana'];
const fontNames = fontList.map(font => getFontName(font));
const fonts = Quill.import('formats/font');
fonts.whitelist = fontNames;
Quill.register(fonts, true);

// Add fonts to CSS style
let fontStyles = '';
fontList.forEach(function (font) {
  let fontName = getFontName(font);
  fontStyles += '.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=' + fontName + ']::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=' + fontName + ']::before {' +
    'content: \'' + font + '\';' +
    'font-family: \'' + font + '\', sans-serif;' +
    '}' +
    '.ql-font-' + fontName + '{' +
    ' font-family: \'' + font + '\', sans-serif;' +
    '}';
});

const node = document.createElement('style');
node.innerHTML = fontStyles;
document.body.appendChild(node);


export default {
  name: 'CreateInstantWinTiles',
  components: {
    CreateRewardModal,
    RewardTemplatesModal,
    Modal,
    CongratulationWithConfetti,
    TabSwitcher,
    ColorPicker,
    ClEditor,
    SwitchButton,
    ScratchCard,
    BarChart,
    WheelTilesPieChart,
    CIIconField,
    Wheel,
    CIInputCol,
    ClResponsiveButton,
    IconWithTooltip,
    CreateRewards
  },
  props: {
    wheelSettings: Object,
    messageSettings: Object,
    settingsData: Object,
    tilesData: Array,
    tilesCount: [String, Number],
    stepNumber: {
      type: [String, Number],
      default: ''
    },
    sectionsFilled: Array,
    instantWinType: {
      type: Number,
      default: 1
    },
    cols: [String, Number],
    rows: [String, Number],
  },
  data() {
    return {
      texts: {
        ...instantWinsTexts,
      },
      descriptions: {
        icon: instantWins.create.tiles.icon,
        probability: instantWins.create.tiles.probability,
        autoProbability: instantWins.create.tiles.autoProbability,
        showWheelImage: instantWins.create.tiles.showWheelImage,
        // glow: instantWins.create.tiles.glow,
        hasPrize: instantWins.create.tiles.hasPrize,
        reward: instantWins.create.tiles.reward,
        text: instantWins.create.tiles.text,
        location: instantWins.create.tiles.location,
        probabilityRequiredText: instantWins.create.tiles.probabilityRequiredText,
        probabilityShouldNotBeZero: instantWins.create.tiles.probabilityShouldNotBeZero,
        probabilityNoNumbersGreaterThan: instantWins.create.tiles.probabilityNoNumbersGreaterThan,
        probabilitySumNotExceedOne: instantWins.create.tiles.probabilitySumNotExceedOne,
        probabilityNoNegativeNumbers: instantWins.create.tiles.probabilityNoNegativeNumbers,
        tileBgColor: instantWins.create.tiles.tileBgColor,
        wheelBgColor: instantWins.create.tiles.wheelBgColor,
        wheelBorderColor: instantWins.create.tiles.wheelBorderColor,
        wheelButtonBgColor: instantWins.create.tiles.wheelButtonBgColor,
        wheelButtonBorderColor: instantWins.create.tiles.wheelButtonBorderColor,
        tileTextPosition: instantWins.create.tiles.tileTextPosition,
        numberOfTiles: instantWins.create.tiles.numberOfTiles,
        numberOfCols: instantWins.create.tiles.numberOfCols,
        numberOfRows: instantWins.create.tiles.numberOfRows,
        selectTile: instantWins.create.tiles.selectTile,
      },
      isToolbar: false,
      hasPrizes: false,
      // glow: true,
      iconValidate: null,
      tiles: this.tilesData,
      localTiles: null,
      tableFields: ['row', 'col', 'contraints', 'probability', 'reward', 'text'],
      localWheelSettings: {
        buttonText: '',
        wheelBackground: '',
        wheelBordersColor: '',
        wheelImage: '',
        wheelArrowImage: '',
        wheelButtonImage: '',
        wheelBorderImage: '',
        spinButtonBorderColor: '',
        spinButtonBackground: '',
        spinButtonBackgroundImage: '',
        icon: ''
      },
      tile: {
        icon: '',
        text: '',
        background: '',
        contraints: [],
        reward: {},
        location: {
          col: 0,
          row: 0
        },
        probability: null
      },
      rewardsData: [],
      transformedRewards: [],
      rerenderKey: 0,
      rerenderSelectColorKey: 0,
      rerenderWheelKey: 0,
      selectedTileIndex: 0,
      requiredFields: ['probability', 'col', 'row'],
      selectedTile: {
        label: 'Tile 1',
        value: '1'
      },
      localTilesCount: this.tilesCount,
      localCols: this.cols,
      localRows: this.rows,
      isShowQuestionMessage: false,
      localAutoProbability: true,
      isShowWheelImage: false,
      tilesOptions: [],
      renderChartKey: 0,
      isPieChart: false,
      isVerticallyText: false,
      localChartsData: [],
      requiredText: null,
      isWheelPreview: false,
      editorOptions: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline'],
              [
                { align: '' },
                { align: 'center' },
                { align: 'right' },
                { align: 'justify' }
              ],
              [{ color: [] }],
              [{ 'font': fonts.whitelist }],
              [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            ],
          },
        },
      },
      tabsList: [
        { value: 'wheelPreview', label: 'Wheel preview' },
        { value: 'barGraph', label: 'Bar Graph' },
        { value: 'pieChart', label: 'Pie Chart' }
      ],
      activeTab: 'wheelPreview',
      isHiddenTileBgButton: false,
      isScratchCardProbabilityView: false,
      isShowCongratsWithConfetti: false,
      winningTileReward: null,
      isWheelPreviewDisabled: false,
      allowedSpaces: [],
      currentSpace: localStorage.getItem('zq-space-name'),
      isShowModal: false,
      isShowRewardTemplatesModal: false,
      isShowRewardModal: false,
      messageGeneral: 'Want to add a reward from a template or create a new reward?',
      modalTitle: 'Adding a reward',
      selectedReward: {},
    };
  },
  watch: {
    'tile.reward'(newVal) {
      const changedProperty = 'reward';
      const index = +this.selectedTile.value - 1;
      const updatedTile = { ...this.tile };

      if (newVal === undefined) {
        updatedTile.reward = {};
      } else {
        updatedTile.reward = newVal;
      }
      this.$set(this.tile, 'reward', updatedTile.reward);
      const payload = { index, updatedTile, changedProperty };
      this.$emit('tile-updated', payload);
    },
    'tile.icon'(newVal) {
      const changedProperty = 'icon';
      const index = +this.selectedTile.value - 1;
      const updatedTile = { ...this.tile };

      if (newVal === undefined) {
        updatedTile.icon = '';
      } else {
        updatedTile.icon = newVal;
      }
      const payload = { index, updatedTile, changedProperty };
      this.$emit('tile-updated', payload);

      if (this.instantWinType === 1) this.$refs.wheel.updateSectionBackgrounds();
    },
    'tile.text'(newVal) {
      const changedProperty = 'text';
      const index = +this.selectedTile.value - 1;
      const updatedTile = { ...this.tile };
      updatedTile.text = newVal;
      const payload = { index, updatedTile, changedProperty };
      this.$emit('tile-updated', payload);
    },
    isVerticallyText(newVal) {
      const changedProperty = 'text';
      const index = +this.selectedTile.value - 1;
      const updatedTile = { ...this.tile };

      if (newVal === true) {
        const isVerticallyText = updatedTile.contraints.includes('isVerticallyText');

        if (!isVerticallyText) {
          updatedTile.contraints.push('isVerticallyText');
        }
      } else {
        const index = updatedTile.contraints.indexOf('isVerticallyText');

        if (index !== -1) {
          updatedTile.contraints.splice(index, 1);
        }
      }
      const payload = { index, updatedTile, changedProperty };
      this.$emit('tile-updated', payload);
    },
    'localWheelSettings.wheelImage'(img) {
      if (img !== null && typeof img !== 'undefined') {
        this.isShowWheelImage = !!img;
      }
    },
    localAutoProbability() {
      this.processChartsData();
    },
    localTilesCount(newVal) {
      if (newVal < 2) {
        this.$emit('update:tilesCount', 2);
        return 2;
      } else {
        this.$emit('update:tilesCount', newVal);
      }

      updateSelectedSection(Number(this.selectedTile.value), newVal);
      this.renderChartKey = newVal;
    },
    tilesData: {
      deep: true,
      handler(newVal) {
        this.localTiles = newVal;
        this.processChartsData();
      }
    },
    localRows(newVal) {
      this.$emit('update:rows', newVal);
      this.localTilesCount = newVal * this.localCols;
    },
    localCols(newVal) {
      this.$emit('update:cols', newVal);
      this.localTilesCount = newVal * this.localRows;
    },
    selectedTile(val) {
      if (val) {
        const index = typeof val === 'object'
          ? Number(val.value - 1)
          : Number(val - 1);

        if (index !== -1) {
          if (this.tilesData[index]) {
            this.hasPrizes = Object.keys(this.tilesData[index].reward).length > 0;

            this.isVerticallyText = this.tilesData[index].contraints.includes('isVerticallyText');
          }

          this.localAutoProbability = this.tilesData[index].autoProbability !== undefined
            ? this.tilesData[index].autoProbability
            : true;

          const isEmpty = Object.keys(this.tilesData[index].reward).length === 0;

          this.tile.icon = this.tilesData[index].icon;
          this.tile.text = this.tilesData[index].text;
          this.tile.reward = this.tilesData[index].reward;
          this.tile.contraints = this.tilesData[index].contraints;
          this.rewardsData = !isEmpty ? [this.tilesData[index].reward] : [];
          this.tile.location = this.tilesData[index].location;
          this.tile.probability = this.tilesData[index].probability;
          this.tile.background = this.tilesData[index].background;
          this.rerenderSelectColorKey++;
        }

        if (this.instantWinType === 1) {
          updateSelectedSection(Number(val.value), this.tilesData.length);
        }

        if (this.instantWinType === 2) {
          this.$refs.scratchcard.updateSelectedCell(Number(val.value));
        }
      }
    },
  },
  mounted() {
    this.updateSectionsOptions();
    window.addEventListener('prizeVisible', this.handlePrizeVisible);
  },
  beforeDestroy() {
    window.removeEventListener('prizeVisible', this.handlePrizeVisible);
  },
  created() {
    this.initialize();
  },
  computed: {
    isWheelSettingsAvailable() {
      return this.allowedSpaces.includes(this.currentSpace);
    },
    sectionsOptions() {
      return Array.from({ length: this.tilesCount }, (_, i) => ({
        value: String(i + 1),
        label: `Tile ${ i + 1 }`
      }));
    },
    pageTitle() {
      return this.instantWinType === 1
        ? this.texts.createPage.tilesStep.spinnerTitle
        : this.texts.createPage.tilesStep.scratchCardTitle;
    }
  },
  methods: {
    ...mapActions('files', ['handleGetFileObjects_item']),
    stopCongratsWithConfetti() {
      this.isShowCongratsWithConfetti = false;
    },
    initialize() {
      this.localTiles = cloneDeep(this.tilesData);
      this.tile.background = this.tilesData[0].background;
      this.localWheelSettings = this.wheelSettings;
      this.hasPrizes = Object.keys(this.localTiles[0].reward).length > 0;
      this.tile.text = this.localTiles[0].text;
      this.localAutoProbability = this.localTiles[0].autoProbability;
      this.tile.probability = this.localTiles[0].probability;
      this.tile.icon = this.localTiles[0].icon;

      if (Object.keys(this.localTiles[0].reward).length > 0) {
        this.updateRewardData([this.localTiles[0].reward]);
      }

      this.isVerticallyText = this.localTiles[0].contraints.includes('isVerticallyText');
      this.processChartsData();
    },
    onProbabilityInput(newVal) {
      if (newVal === '' || isNaN(parseFloat(newVal))) {
        this.resetInvalidProbabilityField();
        this.requiredText = null;
        this.tile.probability = null;
        return;
      }

      const value = parseFloat(newVal);
      this.validateAndSaveProbability(value);
    },
    validateAndSaveProbability(value) {
      this.resetInvalidProbabilityField();

      if (value < 0) {
        this.setInvalidProbabilityField();
        this.requiredText = this.descriptions.probabilityNoNegativeNumbers;
        return;
      }

      if (value > 1) {
        this.setInvalidProbabilityField();
        this.requiredText = this.descriptions.probabilityNoNumbersGreaterThan;
        return;
      }

      // Calculate the total probability excluding the current tile's probability
      const currentIndex = +this.selectedTile.value - 1;
      const totalProbability = this.tilesData.reduce((acc, tile, index) => {
        if (index !== currentIndex) {
          return acc + (+tile.probability || 0);
        }
        return acc;
      }, 0) + value;

      if (totalProbability > 1) {
        this.requiredText = this.descriptions.probabilitySumNotExceedOne;
        this.setInvalidProbabilityField();
        return;
      }

      if (value !== 0) {
        this.tile.probability = value;
        const changedProperty = 'probability';
        const index = +this.selectedTile.value - 1;
        const updatedTile = { ...this.tile, probability: value, autoProbability: false };
        const payload = { index, updatedTile, changedProperty };
        this.$emit('tile-updated', payload);
      }
    },
    async showReward(giftValue) {
      this.winningTileReward = null;
      const winningTile = this.tilesData[giftValue - 1];

      const reward = winningTile.reward;

      if (Object.keys(reward).length !== 0) {
        const iconId = reward.icon;
        const file = await this.handleGetFileObjects_item({ idArray: [iconId] });

        let iconUri;
        if (file.length && file[0] && file[0].uri) {
          iconUri = file[0].uri;
        }

        this.winningTileReward = {
          rewardName: reward.name,
          rewardValue: reward.rewardValue,
          icon: iconUri ? iconUri : ''
        };
      }
      this.isShowCongratsWithConfetti = true;
    },
    removeReward() {
      this.tile.reward = {};
      this.hasPrizes = false;
    },
    handleTabToggle(index) {
      this.activeTab = this.tabsList[index].value;
    },
    handleColorSelected(color) {
      const index = +this.selectedTile.value - 1;

      this.localTiles[index].background = color;

      this.tile.background = color;
      const changedProperty = 'background';

      const updatedTile = { ...this.tile };
      updatedTile.background = color;
      const payload = { index, updatedTile, changedProperty };
      this.$emit('tile-updated', payload);
    },
    handleWheelBackgroundColorSelected(color) {
      this.localWheelSettings.wheelBackground = color;
    },
    handleWheelBordersColorSelected(color) {
      this.$set(this.localWheelSettings, 'wheelBordersColor', color);
    },
    handleSpinButtonBorderColorSelected(color) {
      this.$set(this.localWheelSettings, 'spinButtonBorderColor', color);
    },
    handleSpinButtonBackgroundColorSelected(color) {
      this.localWheelSettings.spinButtonBackground = color;
    },
    handleDeleteWheelImageFile() {
      this.localWheelSettings.wheelImage = '';
    },
    handleDeleteWheelArrowImageFile() {
      this.localWheelSettings.wheelArrowImage = '';
    },
    handleDeleteWheelButtonImageFile() {
      this.localWheelSettings.wheelButtonImage = '';
    },
    handleDeleteWheelBorderImageFile() {
      this.localWheelSettings.wheelBorderImage = '';
    },
    onEditorChange(html) {
      const tileIndex = +this.selectedTile.value - 1;
      this.localTiles[tileIndex].text = html;
      this.tile.text = html;
    },
    onEditorSpinButtonTextChange(html) {
      this.localWheelSettings.buttonText = html;
    },
    handleToggleEditorToolbar(val) {
      this.isToolbar = val;
    },
    setInvalidProbabilityField() {
      const invalidFieldElement = document.getElementsByName('probability')[0];
      let invalidElement = invalidFieldElement;
      if (!invalidFieldElement.classList.contains('zq--form-row')) {
        invalidElement = invalidFieldElement.parentNode;
      }
      invalidElement.classList.add('zq-invalid');
    },
    resetInvalidProbabilityField() {
      const invalidFieldElement = document.getElementsByName('probability')[0];
      let invalidElement = invalidFieldElement;

      if (!invalidFieldElement.classList.contains('zq--form-row')) {
        invalidElement = invalidFieldElement.parentNode;
      }
      invalidElement.classList.remove('zq-invalid');
    },
    onInput(val) {
      if (val && val !== this.selectedTile) {
        this.selectedTile = val;
      }
    },
    createSectionStatusArray() {
      this.$emit('createSectionStatusArray');
    },
    processChartsData() {
      this.localChartsData = [];

      const tilesWithProbability = this.tilesData.filter(tile => +tile.probability !== null);
      const totalProbability = tilesWithProbability.reduce((acc, tile) => acc + +tile.probability, 0);
      const remainingProbability = 1 - totalProbability;

      const tilesWithoutProbability = this.tilesData.filter(tile => +tile.probability === null || +tile.probability === 0);
      const numTilesWithoutProbability = tilesWithoutProbability.length;

      const perTileProbability = remainingProbability / numTilesWithoutProbability;

      for (let i = 0; i < this.tilesData.length; i++) {
        if (this.tilesData[i].probability === null || +this.tilesData[i].probability === 0) {
          this.localChartsData[i] = perTileProbability;
        } else {
          this.localChartsData[i] = +this.tilesData[i].probability;
        }
      }

      this.renderChartKey++;
    },
    updateSectionsOptions() {
      this.tilesOptions = this.sectionsOptions;
    },
    updateRewardData(rewards) {
      this.rewardsData = rewards;
      let localRewards = cloneDeep(rewards);
      let transformedRewards = [];
      if (localRewards.length) {
        localRewards.forEach(reward => {
          reward.rewardTypeId = reward.rewardType.id;
          delete reward.rewardType;
          delete reward.id;
          delete reward.spaceName;
          delete reward.created;
          delete reward.entityType;
          delete reward.entityId;
          delete reward.translatableFields;
          transformedRewards.push(reward);
        });
      }
      this.transformedRewards = transformedRewards;
      this.tile.reward = rewards[0];
      this.isShowRewardModal = false;
      this.selectedReward = {};
    },
    handleTileSelected(val) {
      if (typeof val === 'object') {
        const selectedSection = this.sectionsOptions.find(section => section.value === String(val.cellNumber));

        if (selectedSection) {
          this.selectedTile = selectedSection;
        }
      } else {
        const selectedSection = this.sectionsOptions.find(section => section.value === String(val));

        if (selectedSection) {
          this.selectedTile = selectedSection;
        }
      }

    },
    updateChartsData() {
      this.renderChartKey++;
    },
    handleDeleteFile() {
      this.tile.icon = '';
    },
    handleDeleteButtonIconFile() {
      this.localWheelSettings.icon = '';
    },
    handleDeleteButtonBackgroundFile() {
      this.localWheelSettings.spinButtonBackgroundImage = '';
    },
    hasPrizesUpdate(val) {
      this.hasPrizes = val;
      this.isShowModal = true;
    },
    closeModal() {
      this.hasPrizesUpdate(false);
      this.isShowModal = false;
      this.isShowRewardModal = false;
      this.isShowRewardTemplatesModal = false;
      this.selectedReward = {};
    },
    addRewardFromTemplate() {
      this.isShowModal = false;
      this.isShowRewardTemplatesModal = true;
    },
    createReward() {
      this.isShowModal = false;
      this.isShowRewardModal = true;
    },
    selectReward(reward) {
      this.isShowRewardTemplatesModal = false;
      this.selectedReward = reward;
      this.isShowRewardModal = true;
    },
    swipeTextPositionHandler(val) {
      this.isVerticallyText = val;

      const tileIndex = +this.selectedTile.value - 1;
      if (val === true) {
        const isVerticallyText = this.localTiles[tileIndex].contraints.includes('isVerticallyText');

        if (!isVerticallyText) {
          this.localTiles[tileIndex].contraints.push('isVerticallyText');
        }
      } else {
        const index = this.localTiles[tileIndex].contraints.indexOf('isVerticallyText');

        if (index !== -1) {
          this.localTiles[tileIndex].contraints.splice(index, 1);
        }
      }
    },
    // glowUpdate(val) {
    //   this.glow = val;
    // },
    autoProbabilityUpdate(val) {
      this.localAutoProbability = val;
    },
    showWheelImageUpdate(val) {
      this.isShowWheelImage = val;
    },
    validateRewardSaved(isRewardSaved) {
      this.isRewardSaved = isRewardSaved;
    },
    handleWheelPreview() {
      this.isWheelPreview = !this.isWheelPreview;
      this.isWheelPreviewDisabled = true;

      if (!this.isWheelPreview && this.isShowCongratsWithConfetti) {
        this.$refs.congratulationWithConfettiRef.stopConfetti();
        this.isShowCongratsWithConfetti = false;
      }

      setTimeout(() => {
        this.isWheelPreviewDisabled = false;
      }, 500);
    },
    handleIsScratchCardProbabilityView() {
      this.isScratchCardProbabilityView = !this.isScratchCardProbabilityView;
    }
  }
};
</script>

<style scoped lang="scss">
.tile-settings {
  width: 100%;
  display: flex;
}

.hidden {
  display: none;
}

.settings-title {
  font-size: 18px;
  font-weight: 400;
  color: #3C4B64;
}

#section-select {
  border: none;

  &:focus-visible {
    outline: none;
  }

  .section-select-options {
    border-radius: 12px;
    border: none;
    outline: none;
  }
}

select option {
  border-radius: 12px;
  border: none;
  outline: none;
}

.charts-switch-row {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  .tabSwitcher {
    position: relative;
    margin-left: 60px;
  }

  .charts-switch-label {
    padding-right: 10px;
    margin: 0;
  }
}

.probability-percentage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .probability-percentage-title {
    padding-left: 30px;
    opacity: 0.5;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }

  .total-probability-percentage {
    padding-left: 130px;
  }
}

.content {
  position: relative;

  .message-box {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%);
  }
}

.dividing-line {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #D8DBE0;
}

.right-block {
  background: #F3F3F3;
  //background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.probability-view {
  color: #007DFB;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  .probability-view-btn {
    cursor: pointer;
  }
}


.location {
  display: flex;
  align-items: center;

  .location-row {
    display: flex;
    align-items: center;

    &:last-child {
      margin-left: 5px;
    }

    .input-label {
      //padding-right: 5px;
    }

    .loc {
      width: 100%;
    }
  }
}

.create-wheel-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 80px;
}

.preview-wheel-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
}

.card-preview-wrapper {
  padding-top: 25px;
}

.reward-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  text-align: left;
  background-color: white;
  border-radius: 12px;
}

.reward-table th,
.reward-table td {
  padding: 8px;
}

.reward-table th {
  font-size: 15px;
  font-weight: 400;
  color: #3C4B64;
  font-style: italic;
}

.reward-table td {
  font-size: 13px;
}

.reward-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

</style>