<template>
  <div class="wheel-container-wrapper">
    <div id="wheel-container" class="wheel-container"></div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import { mapActions } from 'vuex';
import { setRadius } from './helpers/radius';
import { createSVG, createWheelGroup, createArrowGroup, } from './helpers/svgConfig.js';
import {
  removeWheelBackground,
  removeWheelBorderImage,
  setWheelBackground,
  setWheelBorderImage
} from './helpers/wheelImages';
import { createWheelSections, updateSelectedSection } from './helpers/wheelSections';
import { createTextElements, updateTextElements } from './helpers/textElements';
import {
  createImageButton,
  removeCenterButton,
  removeImageButton,
  updateWheelCenterButton,
  wheelCenterButton
} from './helpers/wheelCenterButton';
import {
  createCircleBorder,
  removeCircleBorders,
  updateCircleBorder,
  updateCircleBorders
} from './helpers/wheelBorders';
import {
  createArrowPointerWithImage,
  createArrowPointer,
  removeArrowPointer
} from './helpers/arrowPointer';
import { createDotElements, removeDotElements } from './helpers/dotElements';
import { generatePieData } from './helpers/generatePieData';

export default {
  name: 'Wheel',
  props: {
    sectionsCount: {
      type: [Number, String],
      default: 6,
    },
    tilesData: Array,
    sectionsFilled: Array,
    isHideArrow: Boolean,
    isPreviewSpin: Boolean,
    isVerticallyText: Boolean,
    isShowWheelImage: {  type: Boolean, default: false },
    wheelSettings: Object,
  },
  data() {
    return {
      selectedTile: null,
      rerenderWheelKey: 0,
      iconUris: [],
      svg: null,
      radius: this.isPreviewSpin ? 250 : 150,
      colorScale: ['#EE3EC8', '#7ED4E7'],
      sections: Array.from({ length: this.sectionsCount}, (value, index) => ({
        id: index + 1,
        active: false
      })),
      defs: null,
      wheelGroup: null,
      arrowGroup: null,
      prize: {
        title: "Your Prize",
        image: null,
      },
      isWheelSpinning: false,
      localWheelSettings: null,
    }
  },
  mounted() {
    this.updateSectionImages()
    this.createWheel();

    window.addEventListener('sectionSelected', this.handleSectionSelected);
  },
  beforeDestroy() {
    window.removeEventListener('sectionSelected', this.handleSectionSelected)
  },
  created() {
    updateSelectedSection(1, this.sectionsCount);
    setRadius(this.radius)
  },
  watch: {
    'wheelSettings.icon': {
      handler(newVal) {
        this.updateSettingWithFileURI('icon', newVal).then(() => {
          updateWheelCenterButton(this.localWheelSettings);
        });
      }
    },
    'wheelSettings.spinButtonBackgroundImage': {
      handler(newVal) {
        this.updateSettingWithFileURI('spinButtonBackgroundImage', newVal).then(() => {
          updateWheelCenterButton(this.localWheelSettings);
        });
      }
    },
    'wheelSettings.wheelImage': {
      handler(newVal) {
        this.updateSettingWithFileURI('wheelImage', newVal).then(() => {
          const pieData = generatePieData(
              this.radius,
              this.sectionsCount,
              this.getSectionFill,
              this.getSectionStroke,
              this.getSectionStrokeWidth
          )
            if (newVal && newVal.length) {
              const wheelGroup = d3.selectAll('.wheel-group');
              setWheelBackground(wheelGroup, this.radius, this.sectionsCount, this.isPreviewSpin, this.localWheelSettings.wheelImage);

              updateTextElements(
                  pieData,
                  this.tilesData,
                  this.isPreviewSpin,
                  this.sectionsCount,
                  this.getHeightFromHeader,
                  this.getFontFamilyFromClass,
                  this.getSvgTextAnchor,
                  this.handleSectionClick,
                  true
              );
            } else {
              const wheelGroup = d3.selectAll('.wheel-group');
              removeWheelBackground(wheelGroup);

              updateTextElements(
                  pieData,
                  this.tilesData,
                  this.isPreviewSpin,
                  this.sectionsCount,
                  this.getHeightFromHeader,
                  this.getFontFamilyFromClass,
                  this.getSvgTextAnchor,
                  this.handleSectionClick,
                  false
              );
            }
        });
      }
    },
    'wheelSettings.wheelButtonImage': {
      handler(newVal) {
        this.updateSettingWithFileURI('wheelButtonImage', newVal).then(() => {
          if (newVal && newVal.length) {
            removeCenterButton();
            createImageButton(this.svg, this.localWheelSettings.wheelButtonImage, this.isPreviewSpin, this.spinWheel);
          } else {
            removeImageButton(this.svg);
            wheelCenterButton(this.svg, this.isPreviewSpin, this.spinWheel, this.localWheelSettings);
          }
        });
      }
    },
    'wheelSettings.wheelBorderImage': {
      handler(newVal) {
        this.updateSettingWithFileURI('wheelBorderImage', newVal).then(() => {
          if (newVal && newVal.length) {
            removeCircleBorders();
            removeDotElements();
            removeArrowPointer();
            setWheelBorderImage(this.svg, this.isPreviewSpin, this.localWheelSettings.wheelBorderImage);

            if (this.localWheelSettings.wheelArrowImage && this.localWheelSettings.wheelArrowImage.length > 0) {
              const arrowGroup = createArrowGroup(this.svg, this.radius);
              createArrowPointerWithImage(arrowGroup, this.isPreviewSpin, this.localWheelSettings.wheelArrowImage);
            } else {
              const arrowGroup = createArrowGroup(this.svg, this.radius);
              createArrowPointer(arrowGroup, this.isPreviewSpin);
            }
          } else {
            removeWheelBorderImage(this.svg);
            removeArrowPointer();
            const borderContainer = this.svg.append('g')
                .attr('class', 'border-container')
                .attr('transform', `translate(${ this.radius + 4 },${ this.radius + 4 })`);

            createCircleBorder(borderContainer, this.isPreviewSpin, this.localWheelSettings);
            createDotElements(this.svg, borderContainer, this.isPreviewSpin);

            if (this.localWheelSettings.wheelArrowImage && this.localWheelSettings.wheelArrowImage.length > 0) {
              const arrowGroup = createArrowGroup(this.svg, this.radius);
              createArrowPointerWithImage(arrowGroup, this.isPreviewSpin, this.localWheelSettings.wheelArrowImage);
            } else {
              const arrowGroup = createArrowGroup(this.svg, this.radius);
              createArrowPointer(arrowGroup, this.isPreviewSpin);
            }
          }
        });
      }
    },
    'wheelSettings.wheelArrowImage': {
      handler(newVal) {
        this.updateSettingWithFileURI('wheelArrowImage', newVal).then(() => {
          if (newVal && newVal.length) {
            removeArrowPointer();
            const arrowGroup = createArrowGroup(this.svg, this.radius);
            createArrowPointerWithImage(arrowGroup, this.isPreviewSpin, this.localWheelSettings.wheelArrowImage);
          } else {
            removeArrowPointer();
            const arrowGroup = createArrowGroup(this.svg, this.radius);
            createArrowPointer(arrowGroup, this.isPreviewSpin);
          }
        });
      }
    },
    'wheelSettings.buttonText': {
      handler(newVal) {
        this.localWheelSettings.buttonText = newVal;
        updateWheelCenterButton(this.localWheelSettings);
      }
    },
    'wheelSettings.spinButtonBackground': {
      handler(newVal) {
        this.localWheelSettings.spinButtonBackground = newVal;
        updateWheelCenterButton(this.localWheelSettings);
      }
    },
    'wheelSettings.spinButtonBorderColor': {
      handler(newVal) {
        this.localWheelSettings.spinButtonBorderColor = newVal;
        updateWheelCenterButton(this.localWheelSettings);
      }
    },
    'wheelSettings.wheelBackground': {
      handler(newVal) {
        this.localWheelSettings.wheelBackground = newVal;
        updateCircleBorder(this.localWheelSettings);
      }
    },
    'wheelSettings.wheelBordersColor': {
      handler(newVal) {
        this.localWheelSettings.wheelBordersColor = newVal;
        updateCircleBorders(this.localWheelSettings);
      }
    },
    sectionsCount(newVal) {
      d3.selectAll('.path-section').remove();

      createWheelSections(
          this.svg,
          this.radius,
          Number(newVal),
          this.getSectionFill,
          this.getSectionStroke,
          this.getSectionStrokeWidth,
          this.handleSectionClick,
          this.isPreviewSpin
      );

      const pieData = generatePieData(
          this.radius,
          newVal,
          this.getSectionFill,
          this.getSectionStroke,
          this.getSectionStrokeWidth
      )

      if (this.localWheelSettings && this.localWheelSettings.wheelImage && this.isShowWheelImage) {
        const wheelImageUri = this.localWheelSettings.wheelImage;
        const wheelGroup = d3.selectAll('.wheel-group');
        setWheelBackground(wheelGroup, this.radius, this.sectionsCount, this.isPreviewSpin, wheelImageUri);
      }

      updateTextElements(
          pieData,
          this.tilesData,
          this.isPreviewSpin,
          newVal,
          this.getHeightFromHeader,
          this.getFontFamilyFromClass,
          this.getSvgTextAnchor,
          this.handleSectionClick,
          false
      );
    },
    tilesData: {
      deep: true,
      async handler(newVal) {
        const sectionsGroup = d3.selectAll('.path-section');

        sectionsGroup
            .attr('fill', (d) => this.getSectionFill(d.id - 1))
            .on('click', (d, i) => {
              if (!this.isPreviewSpin) {
                this.handleSectionClick(i.id);
              }
            })

        const pieData = generatePieData(
            this.radius,
            this.sectionsCount,
            this.getSectionFill,
            this.getSectionStroke,
            this.getSectionStrokeWidth
        )

        updateTextElements(
            pieData,
            this.tilesData,
            this.isPreviewSpin,
            this.sectionsCount,
            this.getHeightFromHeader,
            this.getFontFamilyFromClass,
            this.getSvgTextAnchor,
            this.handleSectionClick,
            this.isShowWheelImage
        );
      }
    },
    iconUris: {
      deep: true,
      handler(newVal, oldVal) {
        const sectionsGroup = d3.selectAll('.path-section');

        sectionsGroup
          .attr('fill', (d) => this.getSectionFill(d.id - 1))
          .on('click', (d, i) => {
            if (!this.isPreviewSpin) {
              this.handleSectionClick(i.id);
            }
          })
      }
    },
    isShowWheelImage(val) {
      if (this.localWheelSettings && this.localWheelSettings.wheelImage) {
        const wheelImageUri = this.localWheelSettings.wheelImage;
        const wheelGroup = d3.selectAll('.wheel-group');

        if (val === true) {
          setWheelBackground(wheelGroup, this.radius, this.sectionsCount, this.isPreviewSpin, wheelImageUri);
          const pieData = generatePieData(
              this.radius,
              this.sectionsCount,
              this.getSectionFill,
              this.getSectionStroke,
              this.getSectionStrokeWidth
          )

          const isWheelBgImage = !!wheelImageUri
          updateTextElements(
              pieData,
              this.tilesData,
              this.isPreviewSpin,
              this.sectionsCount,
              this.getHeightFromHeader,
              this.getFontFamilyFromClass,
              this.getSvgTextAnchor,
              this.handleSectionClick,
              isWheelBgImage
          );
        } else {
          removeWheelBackground(wheelGroup)
          createWheelSections(
              this.svg,
              this.radius,
              this.sectionsCount,
              this.getSectionFill,
              this.getSectionStroke,
              this.getSectionStrokeWidth,
              this.handleSectionClick,
              this.isPreviewSpin
          );

          const pieData = generatePieData(
              this.radius,
              this.sectionsCount,
              this.getSectionFill,
              this.getSectionStroke,
              this.getSectionStrokeWidth
          )

          updateTextElements(
              pieData,
              this.tilesData,
              this.isPreviewSpin,
              this.sectionsCount,
              this.getHeightFromHeader,
              this.getFontFamilyFromClass,
              this.getSvgTextAnchor,
              this.handleSectionClick,
              false
          );
        }
      }
    }
  },
  methods: {
    ...mapActions('files', ['handleGetFileObjects_item']),
    async createWheel() {
      this.isWheelSpinning = false;
      await this.setLocalWheelSettings(this.wheelSettings);

      const wheelBorderUri = this.localWheelSettings.wheelBorderImage;
      const buttonImageUri = this.localWheelSettings.wheelButtonImage;
      const arrowImgUri = this.localWheelSettings.wheelArrowImage;
      const wheelImageUri = this.localWheelSettings.wheelImage;
      const isWheelBgImage = !!this.wheelSettings.wheelImage;

      if (!this.svg) {
        const {defs, svg} = createSVG(this.radius, this.isPreviewSpin);
        this.svg = svg;
        this.defs = defs;
      }

      if (wheelBorderUri && wheelBorderUri.length) {
        await setWheelBorderImage(this.svg, this.isPreviewSpin, wheelBorderUri);
      }

      // Append a group for the wheel with a specific class
      createWheelGroup(this.svg, this.radius);

      d3.select('#wheel-container').classed('visible', false);

      if (!this.isPreviewSpin) {
        createWheelSections(
          this.svg,
          this.radius,
          this.sectionsCount,
          this.getSectionFill,
          this.getSectionStroke,
          this.getSectionStrokeWidth,
          this.handleSectionClick,
          this.isPreviewSpin
        );
      }


      const pieData = generatePieData(
          this.radius,
          this.sectionsCount,
          this.getSectionFill,
          this.getSectionStroke,
          this.getSectionStrokeWidth
      )

      const wheelGroup = d3.selectAll('.wheel-group');

      if (!buttonImageUri) {
        // wheel center - Spin button
        wheelCenterButton(this.svg, this.isPreviewSpin, this.spinWheel, this.localWheelSettings);
      }

      if (!wheelBorderUri || !wheelBorderUri.length) {
        // Wheel border
        const borderContainer = this.svg.append('g')
            .attr('class', 'border-container')
            .attr('transform', `translate(${ this.radius + 4 },${ this.radius + 4 })`);

        createCircleBorder(borderContainer, this.isPreviewSpin, this.localWheelSettings);

        // Create dots on a circle
        createDotElements(this.svg, borderContainer, this.isPreviewSpin);
      }

      createImageButton(this.svg, buttonImageUri, this.isPreviewSpin, this.spinWheel)

      // Arrow Pointer
      const arrowGroup = createArrowGroup(this.svg, this.radius);

      if (arrowImgUri) {
        await createArrowPointerWithImage(arrowGroup, this.isPreviewSpin, arrowImgUri);
      } else {
        createArrowPointer(arrowGroup, this.isPreviewSpin);
      }

      if (!this.isPreviewSpin) {
        updateSelectedSection(1, this.sectionsCount);
      }

      if (!this.isPreviewSpin && !this.isShowWheelImage) {
        removeWheelBackground(wheelGroup);
      } else if (!this.isPreviewSpin && this.isShowWheelImage) {
        await setWheelBackground(wheelGroup, this.radius, this.sectionsCount, this.isPreviewSpin, wheelImageUri);
      } else if (this.isPreviewSpin && isWheelBgImage) {
        await setWheelBackground(wheelGroup, this.radius, this.sectionsCount, this.isPreviewSpin, wheelImageUri);
      } else if (this.isPreviewSpin && !isWheelBgImage) {
        createWheelSections(
          this.svg,
          this.radius,
          this.sectionsCount,
          this.getSectionFill,
          this.getSectionStroke,
          this.getSectionStrokeWidth,
          this.handleSectionClick,
          this.isPreviewSpin
        );
      }

      if (this.isShowWheelImage || this.isPreviewSpin && isWheelBgImage) {
        createTextElements(
            wheelGroup,
            pieData,
            this.tilesData,
            this.isPreviewSpin,
            this.sectionsCount,
            this.getHeightFromHeader,
            this.getFontFamilyFromClass,
            this.getSvgTextAnchor,
            this.handleSectionClick,
            true
        );
      } else {
        createTextElements(
            wheelGroup,
            pieData,
            this.tilesData,
            this.isPreviewSpin,
            this.sectionsCount,
            this.getHeightFromHeader,
            this.getFontFamilyFromClass,
            this.getSvgTextAnchor,
            this.handleSectionClick,
            false
        );
      }

      d3.select('#wheel-container').classed('visible', true);
    },
    async setLocalWheelSettings(wheelSettings) {
      const fileKeyMappings = {
        icon: wheelSettings.icon,
        spinButtonBackgroundImage: wheelSettings.spinButtonBackgroundImage,
        wheelImage: wheelSettings.wheelImage,
        wheelButtonImage: wheelSettings.wheelButtonImage,
        wheelBorderImage: wheelSettings.wheelBorderImage,
        wheelArrowImage: wheelSettings.wheelArrowImage
      };

      const fileRequests = Object.values(fileKeyMappings)
          .filter(id => id) // Filter out any falsy values (undefined, null, etc.)
          .map(id => this.handleGetFileObjects_item({ idArray: [id] }));

      const files = await Promise.all(fileRequests);

      const copiedSettings = { ...wheelSettings };

      Object.keys(fileKeyMappings).forEach((key, index) => {
        const fileId = fileKeyMappings[key];
        const file = files.find(f => f[0]?.id === fileId);
        if (file && file[0] && file[0].uri) {
          copiedSettings[key] = file[0].uri;
        }
      });

      this.localWheelSettings = copiedSettings;

      return copiedSettings;
    },
    async updateSettingWithFileURI(settingName, newVal) {
      if (newVal) {
        const files = await this.handleGetFileObjects_item({ idArray: [newVal] });
        const file = files[0];
        if (file && file.uri) {
          this.localWheelSettings[settingName] = file.uri;
        }
      } else {
        // Handle the case when newVal is falsy (undefined, null, etc.)
        this.localWheelSettings[settingName] = newVal;
      }
    },
    getHeightFromHeader(text) {
      const regex = /<h(\d)\b/g;
      const match = regex.exec(text);

      if (match) {
        const headerSize = parseInt(match[1]);

        let height = 10
        switch (headerSize) {
          case 1:
            height = 40;
            break
          case 2:
            height = 32;
            break
          case 3:
            height = 28;
            break
          case 4:
            height = 24;
            break
          case 5:
            height = 20;
            break
          case 6:
            height = 18;
            break
        }
        return height * 2 + 11 + 'px';
      } else {
        return this.isPreviewSpin ? '22px' : '16px';
      }
    },
    getSvgTextAnchor(alignClass) {
      switch (alignClass) {
        case 'ql-align-center':
          return 'middle';
        case 'ql-align-right':
          return 'end';
        case 'ql-align-justify':
          return 'start';
        default:
          return 'start';
      }
    },
    getFontFamilyFromClass(fontMatch) {
      const fontName = fontMatch[1];
      switch (fontName) {
        case 'arial':
          return 'Arial, sans-serif';
        case 'courier':
          return 'Courier New, Courier, monospace';
        case 'garamond':
          return 'Garamond, serif';
        case 'tahoma':
          return 'Tahoma, sans-serif';
        case 'times-new-roman':
          return 'Times New Roman, Times, serif';
        case 'verdana':
          return 'Verdana, sans-serif';
        default:
          return 'inherit';
      }
    },
    async updateSectionImages() {
      const iconUris = [];
      for (let i = 0; i < this.tilesData.length; i++) {
        const iconId = this.tilesData[i].icon;
        if (iconId) {
          const file = await this.handleGetFileObjects_item({ idArray: [iconId] });

          if (file && file.length > 0) {
            iconUris.push(file[0].uri);
          } else {
            iconUris.push(null);
          }
        } else {
          iconUris.push(null);
        }
      }
      this.iconUris = iconUris;
    },
    updateSectionBackgrounds() {
      setTimeout(async () => {
        await this.updateSectionImages();
      }, 100)
    },
    handleSectionSelected(event) {
      this.$emit('handleTileSelected', event.detail);
    },
    handleSectionClick(sectionId) {
      const customEvent = new CustomEvent('sectionSelected', { detail: sectionId });
      window.dispatchEvent(customEvent);
    },
    getSectionFill(index) {
      if (this.iconUris[index]) {
        const patternId = `section-pattern-${index}`;
        let pattern = this.defs.select(`#${patternId}`);
        if (pattern.empty()) {
          // If pattern does not exist, create it
          pattern = this.defs.append('pattern')
              .attr('id', patternId)
              .attr('width', '100%')
              .attr('height', '100%')
              .attr('patternContentUnits', 'objectBoundingBox');
          pattern.append('image')
              .attr('class', 'section-image')
              .attr('xlink:href', this.iconUris[index])
              .attr('width', 1.2)
              .attr('height', 1.2)
              .attr('preserveAspectRatio', 'none');
        } else {
          // If pattern already exists, update only xlink:href
          pattern.select('image')
              .attr('xlink:href', this.iconUris[index]);
        }
        return `url(#${patternId})`;
      } else {
        if (this.tilesData[index].background) {
          return this.tilesData[index].background;
        } else {
          return this.colorScale[index % this.colorScale.length];
        }
      }
    },
    getSectionStroke(index) {
      // const isActive = this.sections[index].active
      return '#F2D570'
    },
    getSectionStrokeWidth(index) {
      // const isActive = this.sections[index].active
      return 1
    },
    spinWheel() {
      const sectionsCount = this.sectionsCount;
      this.isWheelSpinning = true;

      const randomIndex = Math.floor(Math.random() * sectionsCount);
      const giftValue = randomIndex + 1;

      const sliceWidth = 360 / sectionsCount;
      const currentAngle = 360 - sliceWidth * (giftValue - 0.5);
      const numberOfRotation = 360 * 5;
      const targetRotation = currentAngle + numberOfRotation;

      const wheelGroup = d3.selectAll('.wheel-group');

      // Create Interpolation for Smooth Transition
      const interpolate = d3.interpolate(0, targetRotation);

      wheelGroup.transition()
          .duration(7000) // You can adjust the duration as needed
          .ease(d3.easeBackOut.overshoot(0.5))
          .tween('rotation', () => (t) => {
            const rotationAngle = interpolate(t);

            // Apply rotation only to the wheel group
            wheelGroup.attr('transform', `translate(${this.radius + 4},${this.radius + 4}) rotate(${rotationAngle})`);
          })
          .on('end', () => {

            const pieData = generatePieData(
                this.radius,
                this.sectionsCount,
                this.getSectionFill,
                this.getSectionStroke,
                this.getSectionStrokeWidth
            )

            // Create darkened areas for all sections except the prize one
            wheelGroup.selectAll('.darkened-section')
                .data(pieData.filter((d) => d.id !== giftValue))
                .enter()
                .append('path')
                .attr('class', 'darkened-section')
                .attr('id', (d) => `darkened-section-${d.id}`)
                .attr('d', (d) => d.arc)
                .attr('fill', 'rgba(0, 0, 0, 0.7)')
                .attr('stroke', 'none')
                .attr('stroke-width', 0)
                .style('pointer-events', 'none');

            wheelGroup.select(`#darkened-section-${giftValue}`).remove();

            const gradientId = 'arrow-gradient';
            const arrowGroup = d3.selectAll('.arrow-group')
            const gradient = arrowGroup.select('svg')
                .append('defs')
                .append('linearGradient')
                .attr('id', gradientId)
                .attr('x1', '0%')
                .attr('y1', '0%')
                .attr('x2', '100%')
                .attr('y2', '0%');

            gradient.append('stop')
                .attr('offset', '0%')
                .attr('stop-color', '#FFD962');

            gradient.append('stop')
                .attr('offset', '100%')
                .attr('stop-color', '#FF8921');

            d3.selectAll('.arrow-pointer-path').attr('fill', `url(#${gradientId})`);

            setTimeout(() => {
              this.$emit('showReward', giftValue)

              wheelGroup.selectAll('.darkened-section').remove();

              // Remove existing gradient
              d3.selectAll('.arrow-group').select('defs').selectAll('linearGradient').remove();

              d3.selectAll('.arrow-pointer-path')
                  .attr('d', "M3.63867 1.23114L25.6557 39.9727C26.1241 40.8977 28.3727 40.8977 28.9348 39.9727L50.8581 1.23114C35.6804 0.197303 19.8469 0.251715 3.63867 1.23114Z")
                  .attr('fill', 'black');

              updateSelectedSection(1, this.sectionsCount)
            }, 1500)
          });
    },
  },
};
</script>

<style scoped lang="scss">
.hidden {
  display: none;
}

#wheel-container {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

#wheel-container.visible {
  opacity: 1;
}

.wheel-container-wrapper {
  position: relative;

  .wheel-container {
    display: flex;
    align-items: center;
    height: 330px;
    overflow: visible;
  }

  .spin-btn {
    position: absolute;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(to right, #f8d86f, #cc943b) !important;
    //background-color: #FFD700 !important;
    font-size: 14px;
    padding-top: 20px;
    color: white;

    .spin-btn-logo {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
    }
    .spin-btn-text {
      position: absolute;
      top: 58%;
      left: 50%;
      transform: translate(-50%);
      font-weight: bold;
    }
  }
}
</style>