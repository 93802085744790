<template>
  <div :class="isColumn ? 'flex-column' : 'd-flex' " class="mt-3 pl-0">
    <div :class="isColumn ? '' : 'pl-0 col-12 col-sm-3 col-md-3 col-lg-3 col-xl-2'">
      <span class="tag-label-text">{{ label }}</span>
    </div>
    <div class="d-flex flex-wrap align-items-center">
      <CButton
        v-if="fileString"
        variant="outline"
        color="dark"
        @click="deleteFile"
        class="mr-2 mb-2 wizard-action-btn position-relative zq--custom-button btn-dark"
        :disabled="false"
      >
        {{ deleteButtonTitle }}
      </CButton>
      <CButton
        v-else
        variant="outline"
        color="dark"
        @click="setCurrentPage"
        class="mr-2 mb-2 wizard-action-btn position-relative zq--custom-button"
        :disabled="false"
      >
        {{ buttonTitle }}
      </CButton>
      <div class="mb-2">
        <img :src="fileString"  alt="file" v-if="fileString" height="60px"/>
        <img
          v-if="icon"
          src="https://2.bp.blogspot.com/-7-shYuJQqn0/WfB2lCRFB3I/AAAAAAAAAcY/0SrOJlGlqk4ArEJRTjnK5JKcQ0cG7aGtQCLcBGAs/s1600/Broken%2BImage.gif"
          width="25"
          height="35"
          class="mr-1"
          alt="icon"
        />
        <span
          v-if="icon"
          @click="handlePreviewIcon"
          class="icon-subtitle__preview"
        >
            Preview
          </span>
        <IconWithTooltip
          class="ml-2 position-relative"
          :text="description"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IconWithTooltip from '@/shared/UI/IconWithTooltip';

export default {
  name: "SelectIcon",
  components: {
    IconWithTooltip
  },
  props: {
    icon: String,
    fileString: String,
    label: {
      type: String,
      default: 'Icon'
    },
    descriptions: {
      type: Object,
      default() {
        return {};
      }
    },
    iconKey: {
      type: String,
      default: 'icon'
    },
    isColumn: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonTitle() {
      if (this.label.split(' ')[0] === 'Banner') {
        return 'Select a Banner'
      } else {
        return 'Select an Icon'
      }
    },
    deleteButtonTitle() {
      if (this.label.split(' ')[0] === 'Banner') {
        return 'Delete a Banner'
      } else {
        return 'Delete an Icon'
      }
    }
  },
  data() {
    return {
      description: "An image of the reward to display in the widget upon issuing an achievement. All images can be pre uploaded inside the 'Web Assets' page. The parameter is optional and editable depending on the 'Live status' parameter of the achievement"
    }
  },
  created() {
    if (this.descriptions[this.iconKey]) {
      this.description = this.descriptions[this.iconKey];
    }
  },
  methods: {
    setCurrentPage() {
      this.$emit('setCurrentPage', {page: 'repositories'});
    },
    deleteFile() {
      this.$emit('deleteFile', null);
    }
  }
}
</script>

<style scoped>
.btn-dark {
  color: #ffffff !important;
}
</style>