<template>
  <div class="zq--data-picker--wrap" :class="{'zq-invalid': !isValid}">
    <date-picker
      :name="fieldName"
      v-model="date"
      type="datetime"
      :placeholder="placeholder"
      :class="{'zq-invalid': !isValid}"
      format="ddd, DD MMMM YYYY HH:mm:ss UTC"
      :disabled-date="disabledDate"
      :disabled-time="disabledTime"
      :disabled="disabled"
      class="zq--data-picker"
      popup-class="zq--data-picker--popup"
      ref="datePicker"
    >
      <template #icon-calendar>
        <img
          width="16"
          height="16"
          alt="calendar"
          src="../../assets/icons/calendar/calendar.png"
          srcset="../../assets/icons/calendar/calendar@2x.png 2x, ../../assets/icons/calendar/calendar@3x.png 3x"
        >
      </template>
    </date-picker>
    <slot name="icon"></slot>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker
  },
  props: {
    dateProp: [Date, String],
    placeholder: String,
    fieldName: String,
    disabledDate: Function,
    disabledTime: Function,
    disabled: {
      type: Boolean,
      default: false
    },
    isValid: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      date: ''
    }
  },
  created() {
    this.date = this.dateProp
  },
  watch: {
    dateProp(date) {
      this.date = date;
    },
    date(val) {
      this.$refs.datePicker.$el.classList.remove('zq-invalid');
      this.$emit('input', val)
    }
  }
}
</script>

<style lang="scss">
@import "../../assets/scss/mixins";

.zq--data-picker--wrap {
  position: relative;
  &__error {
    margin-bottom: 14px;
  }
}

.mx-datepicker {
  min-width: 300px;
  @include media-breakpoint-down('576px') {
    min-width: 150px;
    width: 300px;
  }
  @include media-breakpoint-down('350px') {
    width: 230px;
  }
  &.zq-invalid > .mx-input-wrapper > .mx-input {
    border: solid 1px var(--zq-warn) !important;
  }
}

.mx-time-header {
  .mx-btn, .mx-btn-text, .mx-time-header-title {
    &:before {
      content: '«';
    }
  }
}

.error {
  .mx-input {
    border: 1px solid red;

  }
  &:after {
    color: #e55353;
    position: absolute;
    content: 'Required';
    top: 3.6em;
    left: 0;
    font-size: 11px;
  }
}

</style>