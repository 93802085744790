<template>
  <div>
    <CModal
      v-if="!isShowLoader"
      :show.sync="showModal"
      :title="'Select a reward template'"
      centered
      :fade="true"
    >
      <template #header>
        <h5 class="modal-title">{{ 'Reward templates' }}</h5>
        <button type="button" class="close" @click="closeByIcon">
          <span aria-hidden="true">&times;</span>
        </button>
      </template>
      <div class="rewards-list-wrapper">
        <table v-if="rewardsList">
          <tbody>
          <tr v-for="reward in rewardsList" :key="reward.id">
            <td :title="reward.name" class="ellipsis">{{ reward.name }}</td>
            <td :title="reward.rewardType.key" class="ellipsis">{{ reward.rewardType.key }}</td>
            <td><a @click="selectReward(reward)" class="link">Use</a></td>
          </tr>
          </tbody>
        </table>
        <div v-else>
          no rewards found.
        </div>
      </div>
      <template #footer-wrapper></template>
    </CModal>
    <TableLoader v-if="isShowLoader"/>
  </div>

</template>

<script>
import { mapActions } from 'vuex';
import TableLoader from '@/components/table/Loader.vue';

export default {
  name: 'RewardTemplatesModal',
  components: { TableLoader },
  props: {
    entityType: String,
    isShowRewardTemplatesModal: Boolean
  },
  data() {
    return {
      showModal: false,
      isShowLoader: false,
      rewardsList: null,
      query: [{
        'queryFields': ['tags'],
        'queryValue': 'template'
      }],
      must: [{
        queryField: 'entityType',
        queryValues: [this.entityType]
      }],
      isSelectedReward: false
    };
  },
  watch: {
    isShowRewardTemplatesModal(val) {
      this.showModal = val;
    },
    showModal(val) {
      if (val === true) {
        this.isShowLoader = true;
        this.handleGetRewardsByQuery({
          queryRequest: {
            must: this.must,
            sortBy: this.sortBy,
            skip: 0,
            limit: 999,
          }
        }).then(data => {
          this.rewardsList = data;
          this.isShowLoader = false;
        });
      }
    }
  },

  methods: {
    ...mapActions('rewards', ['handleGetRewardsByQuery']),
    selectReward(reward) {
      this.$emit('selectReward', reward);
    },
    closeByIcon() {
      this.$emit('closeModal');
      this.modal = false;
    },
  }
};
</script>

<style scoped lang="scss">
.rewards-list-wrapper {
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}

.ellipsis {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.link {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.link:hover {
  color: darkblue;
}

::v-deep .modal-footer {
  display: none;
}
</style>
