import { render, staticRenderFns } from "./Wheel.vue?vue&type=template&id=519e42c2&scoped=true"
import script from "./Wheel.vue?vue&type=script&lang=js"
export * from "./Wheel.vue?vue&type=script&lang=js"
import style0 from "./Wheel.vue?vue&type=style&index=0&id=519e42c2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "519e42c2",
  null
  
)

export default component.exports